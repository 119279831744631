* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Primary Red */

  --imperial-red-500: hsla(354, 81%, 56%, 1);
  --imperial-red-600: hsla(354, 65%, 51%, 1);
  --imperial-red-700: hsla(354, 63%, 45%, 1);
  --imperial-red-800: hsla(354, 63%, 39%, 1);
  --imperial-red-900: hsla(354, 63%, 34%, 1);

  --almond: hsla(30, 38%, 82%, 1);
  --hunter-green: hsla(138, 29%, 25%, 1);
  --maximum-blue-green: hsla(187, 58%, 53%, 1);

  /* Primary Blue */

  --prussian-blue-500: hsla(205, 100%, 13%, 1);
  --prussian-blue-600: hsla(205, 100%, 11%, 1);
  --prussian-blue-700: hsla(206, 100%, 9%, 1);
  --prussian-blue-800: hsla(204, 100%, 6%, 1);
  --prussian-blue-900: hsla(206, 100%, 4%, 1);

  --white: hsla(0, 0%, 100%, 1);
  --black: hsla(0, 0%, 0%, 1);
  --gray: hsla(0, 0%, 50%, 1);

  /* Effects */

  --shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  --shadow-hover: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  --transition: all 0.2s ease-in-out;
  --maxWidth: 1200px;
  --letterSpacing: 0.1rem;

  /* Font */

  --primaryFont: 'Poppings', sans-serif;
  --secondaryFont: 'Roboto', sans-serif;
}

body {
  font-family: var(--primaryFont);
  background-color: #eaeded;
  color: var(--black);
  line-height: 1.75;
}

a {
  text-decoration: none;
  color: var(--prussian-blue-500);
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
  color: var(--prussian-blue-500);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--secondaryFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

.Toastify__toast {
  text-transform: capitalize;
}
